import React, { PropsWithChildren } from "react";
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby";
import styled from "styled-components";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink

type Props = PropsWithChildren<GatsbyLinkProps<any>>;

const Link = ({
  children,
  url,
  activeClassName,
  partiallyActive,
  ...other
}: Props) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(url);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLinkStyled
        to={url}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLinkStyled>
    );
  }
  return (
    <a href={url} {...other}>
      {children}
    </a>
  );
};

const GatsbyLinkStyled = styled(GatsbyLink)`
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`;
export default Link;
