import React from "react";
import styled from "styled-components";
import { Spinner } from "@shopify/polaris";

function GenericPageLoad() {
  return (
    <PageContainer>
      <Spinner size="large" color="teal" />
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
export default GenericPageLoad;
