// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/codebuild/output/src391657768/src/ruedesmille-reserved-area/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("/codebuild/output/src391657768/src/ruedesmille-reserved-area/src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("/codebuild/output/src391657768/src/ruedesmille-reserved-area/src/pages/forgot-password.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-index-tsx": () => import("/codebuild/output/src391657768/src/ruedesmille-reserved-area/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src391657768/src/ruedesmille-reserved-area/.cache/data.json")

