import React, { useEffect, useState, useContext } from "react";
import ReactGA from "react-ga";
import {
  currentAuthenticatedUser,
  signIn,
  completeSignIn,
  ISignIn,
  ICompleteSignIn,
  signOut,
} from "api/auth";
import { Logger } from "aws-amplify";

import GenericPageLoad from "../components/GenericPageLoad";

interface UserContext {
  loading: boolean;
  user?: User;
  login: (user: User) => Promise<void>;
  logout: () => Promise<void>;
}

const defaultState = {
  loading: false,
};

const UserContext = React.createContext<UserContext>(defaultState);

export const UserProvider: React.SFC<any> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [user, setUser] = useState(undefined);
  const logger = new Logger("User");

  async function updateUser() {
    setIsLoading(true);
    try {
      const session = await currentAuthenticatedUser();
      //FIXME: change isAdmin with enum
      const userLogged = {
        email: session.signInUserSession.idToken.payload.email,
        isAdmin:
          session.signInUserSession.idToken.payload["cognito:groups"] &&
          session.signInUserSession.idToken.payload["cognito:groups"].includes(
            "Admin"
          ),
      };
      // console.log("SET GA USER: ", userLogged.email);
      ReactGA.set({ userId: userLogged.email });
      setUser(userLogged);
    } catch (err) {
      logger.error(err);
      console.log(err);
    } finally {
      setIsLoading(false);
      setShouldFetch(false);
    }
  }
  useEffect(() => {
    if (shouldFetch) {
      updateUser();
    }
  }, []);

  function logout(onSignOut) {
    signOut()
      .then(_ => {
        setUser(undefined);
        onSignOut();
      })
      .catch(err => console.log(err));
  }
  const completeLogin = ({ user, newPassword }: ICompleteSignIn) =>
    completeSignIn({ user, newPassword });

  async function login({ email, password }: ISignIn) {
    return signIn({ email, password });
  }

  return (
    <UserContext.Provider
      value={{ isLoading, user, logout, login, completeLogin, updateUser }}
    >
      {isLoading ? <GenericPageLoad /> : <div>{children}</div>}
    </UserContext.Provider>
  );
};

export { UserContext };

export function useUser() {
  const value = useContext(UserContext);
  if (!value) {
    throw new Error("UserProvider Context is missing");
  }
  return value;
}
