import React, { useEffect } from "react";
import Amplify from "aws-amplify";
import awsconfig from "aws-exports";
import { AppProvider } from "@shopify/polaris";
import { UserProvider } from "hooks/useUser";
// import { Link } from "gatsby";
import { AppSyncProvider } from "../hooks/useAppSync";
import "@shopify/polaris/styles.css";
import rdmLogo from "images/rdm-logo.png";
import Link from "components/Link";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/browser";

Amplify.configure(awsconfig);

const theme = {
  colors: {
    topBar: {
      background: "#34485f",
    },
  },
  logo: {
    width: 124,
    topBarSource: rdmLogo,
    url: "/",
    accessibilityLabel: "Ruedesmille",
  },
};

function App({ children }) {
  useEffect(() => {
    // console.log("process.env", process.env);
    console.log("INIT GA:", process.env.GATSBY_GA);
    console.log("process.env", process.env);
    ReactGA.initialize(process.env.GATSBY_GA);
    Sentry.init({
      environment: "dev",
      dsn: "https://573c21ee25504222b0325baf0a3b7a38@sentry.io/1520339",
    });
  }, []);

  return (
    <div>
      <AppProvider linkComponent={Link} theme={theme}>
        <UserProvider>
          <AppSyncProvider>
            <div>{children}</div>
          </AppSyncProvider>
        </UserProvider>
      </AppProvider>
    </div>
  );
}

export default App;
