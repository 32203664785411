module.exports = [{
      plugin: require('/codebuild/output/src391657768/src/ruedesmille-reserved-area/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src391657768/src/ruedesmille-reserved-area/node_modules/gatsby-plugin-sentry/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://573c21ee25504222b0325baf0a3b7a38@sentry.io/1520339","environment":"production","enabled":true},
    },{
      plugin: require('/codebuild/output/src391657768/src/ruedesmille-reserved-area/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
