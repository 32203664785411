import React, { useEffect, useState, useContext } from "react";
import awsconfig from "aws-exports";
import AWSAppSyncClient from "aws-appsync";
import { Auth } from "aws-amplify";
import gql from "graphql-tag";
import { createFile } from "graphql/mutations";

interface AppSyncContext {
  loading: boolean;
  uploadFiles: () => Promise<void>;
}
const defaultState = {
  loading: false,
};
const GRAPHQL_API_REGION = awsconfig.aws_appsync_region;
const GRAPHQL_API_ENDPOINT_URL = awsconfig.aws_appsync_graphqlEndpoint;
const S3_BUCKET_REGION = awsconfig.aws_user_files_s3_bucket_region;
const S3_BUCKET_NAME = awsconfig.aws_user_files_s3_bucket;
const AUTH_TYPE = awsconfig.aws_appsync_authenticationType;

const AppSyncContext = React.createContext<AppSyncContext>(defaultState);

export const AppSyncProvider: React.SFC<any> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [uploadErrors, setUploadErrors] = useState([]);
  const [uploadStatus, setUploadStatus] = useState([
    // {
    //   folderId: "9c96dd4b-a3dd-49ef-88f9-232e14e04b7e",
    //   totalFiles: 10,
    //   filesUploaded: 1,
    // },
    // {
    //   folderId: "9c96dd4b-a3dd-49ef-88f9-232e14e04b7e",
    //   totalFiles: 10,
    //   filesUploaded: 1,
    // },
  ]);
  function preventReload(e) {
    e.preventDefault();
    e.returnValue = "";
  }

  useEffect(() => {
    if (uploadStatus.length) {
      window.addEventListener("beforeunload", preventReload);
    }
    return () => {
      window.removeEventListener("beforeunload", preventReload);
    };
  }, [uploadStatus]);

  let client = undefined;
  useEffect(() => {
    client = new AWSAppSyncClient({
      url: GRAPHQL_API_ENDPOINT_URL,
      region: GRAPHQL_API_REGION,
      auth: {
        type: AUTH_TYPE,
        jwtToken: async () =>
          (await Auth.currentSession()).getAccessToken().getJwtToken(),
      },
      complexObjectsCredentials: () => Auth.currentCredentials(),
      disableOffline: true,
    });
  }, []);

  async function uploadFiles({ files, folderId, onUploadedFile }) {
    const visibility = "protected";
    const { identityId } = await Auth.currentCredentials();

    try {
      const folderUploadStatus = uploadStatus.find(
        u => u.folderId === folderId
      );
      let newUploadStatusForThisFolder = {};
      if (folderUploadStatus) {
        newUploadStatusForThisFolder = {
          folderId,
          totalFiles: folderUploadStatus.totalFiles + files.length,
          filesUploaded: folderUploadStatus.filesUploaded,
          currentlyUploading: folderUploadStatus.currentlyUploading,
        };

        setUploadStatus([
          ...uploadStatus.filter(u => u.folderId !== folderId),
          newUploadStatusForThisFolder,
        ]);
      } else {
        newUploadStatusForThisFolder = {
          folderId,
          totalFiles: files.length,
          filesUploaded: 0,
          currentlyUploading: "test.txt",
        };
        setUploadStatus([...uploadStatus, newUploadStatusForThisFolder]);
      }

      const client = new AWSAppSyncClient({
        url: GRAPHQL_API_ENDPOINT_URL,
        region: GRAPHQL_API_REGION,
        auth: {
          type: AUTH_TYPE,
          jwtToken: async () =>
            (await Auth.currentSession()).getAccessToken().getJwtToken(),
        },
        complexObjectsCredentials: () => Auth.currentCredentials(),
        disableOffline: true,
      });
      const filesUploaded = [];
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        const { name: fileName, type: mimeType } = file;
        const key = `${visibility}/${identityId}/${fileName}`;
        const fileToUpload = {
          bucket: S3_BUCKET_NAME,
          region: S3_BUCKET_REGION,
          key,
          mimeType,
          localUri: file,
        };
        try {
          const { data } = await client.mutate({
            mutation: gql(createFile),
            variables: {
              input: {
                name: fileName,
                fileFolderId: folderId,
                owner: identityId,
                file: fileToUpload,
                size: file.size,
              },
            },
          });
          const nameSplitted = data.createFile.name.split(".");
          const imagesFormat = ["jpg", "jpeg", "png", "gif", "svg"];
          let type = "file";
          if (imagesFormat.includes(nameSplitted[nameSplitted.length - 1])) {
            type = "image";
          }
          const fileUploaded = {
            id: data.createFile.id,
            name: data.createFile.name,
            type,
            size: data.createFile.size,
            file: {
              key: data.createFile.file.key,
              bucket: data.createFile.file.bucket,
              region: data.createFile.file.region,
            },
          };
          const newUpdatedUploadStatus = {
            ...newUploadStatusForThisFolder,
            filesUploaded:
              newUploadStatusForThisFolder.filesUploaded + index + 1,
          };
          setUploadStatus([...uploadStatus, newUpdatedUploadStatus]);
          filesUploaded.push(fileUploaded);
        } catch (err) {
          Sentry.captureException(err);
          const newUploadStatus = uploadStatus.filter(u => u.id !== folderId);
          setUploadErrors([
            ...uploadErrors,
            {
              id: Math.floor(Math.random() * 10000) + 1,
              fileName,
              dismiss: errorId => {
                setUploadErrors(uploadErrors.filter(e => e.id !== errorId));
              },
            },
          ]);
          setUploadStatus(newUploadStatus);
        }
      }
      onUploadedFile({ filesUploaded, folderId });
      const newUploadStatus = uploadStatus.filter(u => u.id !== folderId);
      setUploadStatus(newUploadStatus);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <AppSyncContext.Provider
      value={{ loading, uploadFiles, uploadStatus, uploadErrors }}
    >
      {children}
    </AppSyncContext.Provider>
  );
};

export { AppSyncContext };

export function useAppSync() {
  const value = useContext(AppSyncContext);
  if (!value) {
    throw new Error("AppSyncProvider Context is missing");
  }
  return value;
}
