// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:503febd7-185f-4d5e-b58e-07218efce9fc",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_1ZsgbyrSb",
    "aws_user_pools_web_client_id": "9pik9hd0hnj45k80i0l0iahi",
    "oauth": {},
    "aws_user_files_s3_bucket": "rdm-reserved-areac2fb3399a5e541adb1e2c6a272909e1e-production",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://prh6sjqe7zayjlffdynt66lbfi.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
