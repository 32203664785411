import { Auth } from "aws-amplify";

export interface ISignIn {
  email: string;
  password: string;
}

export const signIn = ({ email, password }: ISignIn) =>
  Auth.signIn(email, password);

export const signOut = () => Auth.signOut();

export const currentAuthenticatedUser = () => Auth.currentAuthenticatedUser();

export interface ICompleteSignIn {
  user: any;
  newPassword: string;
}

export const completeSignIn = ({ user, newPassword }: ICompleteSignIn) =>
  Auth.completeNewPassword(user, newPassword, {});

export const resendCodeForResetPassword = email => Auth.forgotPassword(email);

export const resetPassword = ({ email, code, newPassword }) =>
  Auth.forgotPasswordSubmit(email, code, newPassword);
