// tslint:disable
// this is an auto generated file. This will be overwritten

export const createUser = `mutation CreateUser($email: String!) {
  createUser(email: $email) {
    Username
    Attributes {
      Name
      Value
    }
    UserCreateDate
    UserLastModifiedDate
    Enabled
    UserStatus
  }
}
`;
export const deleteUser = `mutation DeleteUser($userName: String!) {
  deleteUser(userName: $userName)
}
`;
export const createFolder = `mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    id
    name
    description
    files {
      items {
        id
        owner
        name
        size
        createdAt
      }
      nextToken
    }
    parentID
    status
    createdAt
  }
}
`;
export const updateFolder = `mutation UpdateFolder($input: UpdateFolderInput!) {
  updateFolder(input: $input) {
    id
    name
    description
    files {
      items {
        id
        owner
        name
        size
        createdAt
      }
      nextToken
    }
    parentID
    status
    createdAt
  }
}
`;
export const deleteFolder = `mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    id
    name
    description
    files {
      items {
        id
        owner
        name
        size
        createdAt
      }
      nextToken
    }
    parentID
    status
    createdAt
  }
}
`;
export const createFile = `mutation CreateFile($input: CreateFileInput!) {
  createFile(input: $input) {
    id
    owner
    name
    file {
      bucket
      region
      key
    }
    size
    createdAt
    folder {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
  }
}
`;
export const updateFile = `mutation UpdateFile($input: UpdateFileInput!) {
  updateFile(input: $input) {
    id
    owner
    name
    file {
      bucket
      region
      key
    }
    size
    createdAt
    folder {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
  }
}
`;
export const deleteFile = `mutation DeleteFile($input: DeleteFileInput!) {
  deleteFile(input: $input) {
    id
    owner
    name
    file {
      bucket
      region
      key
    }
    size
    createdAt
    folder {
      id
      name
      description
      files {
        nextToken
      }
      parentID
      status
      createdAt
    }
  }
}
`;
